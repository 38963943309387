import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";

const baseUrl = `${config.apiUrl}/api`;
export const discountCodeService = {
  addDiscountCode,
  fetchDiscountCode,
  deleteDiscountCode,
};

//// function for adding the discount code ------------------------------------------/
async function addDiscountCode(value: any) {
  let formattedStartDate;
  let formattedEndDate;

  if (value.startDate !== null) {
    const date = new Date(value.startDate);
    // Format the date in local time as YYYY-MM-DD (without timezone conversion)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    formattedStartDate = `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  }
  if (value.endDate !== null) {
    const date = new Date(value.endDate);
    // Format the date in local time as YYYY-MM-DD (without timezone conversion)
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    formattedEndDate = `${year}-${month}-${day}`; // Format as YYYY-MM-DD
  }
  return fetchWrapper.post(`${baseUrl}/add-discount-code`, {
    code: value.code,
    tag: value.tag,
    quota: value.quota,
    startDate: formattedStartDate,
    endDate: formattedEndDate,
  });
}

//// Function for fetching the discount code ---------------------------------------------/
async function fetchDiscountCode() {
  return fetchWrapper.get(`${baseUrl}/get-discount-codes`);
}

//// Function for \deltting the code -----------------------------/
async function deleteDiscountCode(codeId: any) {
  return fetchWrapper.delete(
    `${baseUrl}/delete-discount-code?codeId=${codeId}`
  );
}

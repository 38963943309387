import React, { useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Container,
  Grid,
  Snackbar,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  TemplatesInterface,
  TotalConversationResponse,
  WhatsappMiddleSectionProps,
} from "../../interface/WhatsAppPageInterface";

import TemplateManager from "./TemplateManager";
import { whatsappService } from "../../services/whatsapp.service";

function WhatsAppMiddleSection({
  activeTab,
  setActiveTab,
  handleSend,
  loading,
  templates,
  setTemplates,
  templateDetails,
  setTemplateDetails,
}: WhatsappMiddleSectionProps) {
  const [chatLoading, setChatLoading] = useState(false);
  const [conversations, setConversations] = useState<
    TotalConversationResponse[]
  >([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const handleTabChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
  };

  useEffect(() => {
    async function fetchAllConversation() {
      try {
        setChatLoading(true);
        const response = await whatsappService.getWhatsAppMessage();
        if (response.success) {
          setConversations(response.data);
        } else {
          setConversations([]);
        }
      } catch (error) {
      } finally {
        setChatLoading(false);
      }
    }
    fetchAllConversation();
  }, []);
  const renderTabContent = () => {
    if (activeTab === 0) {
      // Chat Content
      return (
        <>
          {conversations && conversations.length > 0 ? (
            <Box
              sx={{
                maxHeight: "500px", // Limit the height of the scrollable area
                overflowY: "auto", // Enable vertical scrolling
                pr: 1, // Padding for smooth appearance
              }}
            >
              {conversations.map(
                (chat: TotalConversationResponse, index: number) => (
                  <Card
                    key={index}
                    variant="outlined"
                    sx={{
                      p: 3,
                      mb: 2,
                      borderRadius: "12px",
                      boxShadow: "0px 4px 6px rgba(0,0,0,0.1)",
                      "&:hover": { bgcolor: "action.hover" },
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", mb: 2, color: "text.primary" }}
                    >
                      Conversation #{index + 1}
                    </Typography>

                    {/* Displaying the entire object as JSON */}
                    <pre
                      style={{
                        background: "#f4f4f4",
                        padding: "15px",
                        borderRadius: "8px",
                        overflowX: "auto",
                        fontSize: "14px",
                      }}
                    >
                      {JSON.stringify(chat, null, 2)}
                    </pre>
                  </Card>
                )
              )}
            </Box>
          ) : (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="body1" color="text.secondary">
                No conversations available
              </Typography>
            </Container>
          )}
        </>
      );
    } else if (activeTab === 1) {
      // Campaign Content
      return (
        <TemplateManager
          handleSend={handleSend}
          loading={loading}
          templates={templates}
          setTemplates={setTemplates}
          templateDetails={templateDetails}
          setTemplateDetails={setTemplateDetails}
        />
      );
    }
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(!snackbarOpen);
  }

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      {/* Tabs for Chat and Campaign */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        centered
        sx={{
          ".MuiTabs-flexContainer": { justifyContent: "space-evenly" },
          ".MuiTab-root": {
            textTransform: "none",
            fontWeight: "bold",
          },
        }}
      >
        <Tab label="Chat" />
        <Tab label="Campaign" />
      </Tabs>
      {chatLoading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <> {renderTabContent()}</>
      )}
      {/* Dynamic Content Based on Active Tab */}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Grid>
  );
}

export default WhatsAppMiddleSection;

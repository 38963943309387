/** @format */
import {
  OwnWorkoutInterface,
  SetInterface,
} from "../interface/OwnCreatedWorkoutInterface";
// Function to check if two dates are the same day
function isSameDay(date1: any, date2: any) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

//// Function for getting the exercise of a particular day-------------------/
async function getCurrentDayExercises(
  workouts: OwnWorkoutInterface[],
  currentDate: Date
): Promise<any[]> {
  const currentDayExercises: any[] = [];

  try {
    for (const workout of workouts) {
      for (const exercise of workout.exercises) {
        // Filter sets that match the current date (ignoring time)
        const matchingSets = exercise.sets.filter((set: SetInterface) => {
          // Parse the `dateTime` string into a Date object
          const setDate = new Date(set.dateTime); // Assumes dateTime is in local or UTC
          return isSameDay(setDate, currentDate);
        });

        if (matchingSets.length > 0) {
          // Add the exercise with only the matching sets
          currentDayExercises.push({
            ...exercise,
            sets: matchingSets, // Replace sets with only the ones matching the current day
          });
        }
      }
    }

    return currentDayExercises;
  } catch (error) {
    console.error("Error filtering exercises:", error);
    return [];
  }
}
async function getCurrentDayWorkout(
  workouts: OwnWorkoutInterface[],
  currentDate: Date
): Promise<OwnWorkoutInterface[]> {
  const filteredWorkouts = [];
  try {
    for (const workout of workouts) {
      let workoutHasMatchingExercise = false;

      for (const exercise of workout.exercises) {
        if (
          exercise.sets.some((set: SetInterface) =>
            isSameDay(new Date(set.dateTime), currentDate)
          )
        ) {
          workoutHasMatchingExercise = true;
          break;
        }
      }

      if (workoutHasMatchingExercise) {
        filteredWorkouts.push(workout);
      }
    }

    return filteredWorkouts;
  } catch (error) {
    return filteredWorkouts;
  }
}
export const workoutUtilsFunctions = {
  getCurrentDayWorkout,
  getCurrentDayExercises,
  isSameDay,
};

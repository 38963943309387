import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  TextField,
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Snackbar,
  IconButton,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { discountCodeService } from "../services/discountcode.service";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import TopAppBar from "../modules/TopAppBar";

// Validation schema
const validationSchema = Yup.object({
  code: Yup.string().required("Code is required"),
  tag: Yup.string().required("Tag is required"),
  quota: Yup.number()
    .min(0, "Quota must be positive")
    .required("Quota is required"),
});

const DiscountCode = () => {
  const [codes, setCodes] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [codeUpdateLoading, setCodeUpdateLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  async function fetchCodes() {
    setLoading(true);
    try {
      const response = await discountCodeService.fetchDiscountCode();
      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes(response.data);
        setLoading(false);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setLoading(false);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchCodes();
  }, []);

  const handleAddCode = async (values: any) => {
    setCodeUpdateLoading(true);
    try {
      const response = await discountCodeService.addDiscountCode(values);

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes([
          ...codes,
          {
            _id: response.data._id,
            code: values.code,
            tag: values.tag,
            quota: values.quota,
            startDate: values.startDate,
            endDate: values.endDate,
          },
        ]);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      console.error("Error adding code:", error);
    } finally {
      setCodeUpdateLoading(false);
    }
  };

  const handleDeleteCode = async (_id: number) => {
    setDeleteLoading(true);
    try {
      const response = await discountCodeService.deleteDiscountCode(_id);

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setCodes(codes.filter((code) => code._id !== _id));
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error) {
      console.error("Error deleting code:", error);
    }
    setDeleteLoading(false);
  };

  function handleCloseSnackbar(): void {
    setSnackbarOpen(false);
  }

  return (
    <>
      <TopAppBar />
      <Container maxWidth="lg" style={{ marginTop: "40px", padding: "0" }}>
        {loading ? (
          <Container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Container>
        ) : (
          <Paper
            elevation={3}
            style={{
              padding: "24px",
              borderRadius: "12px",
              backgroundColor: "#f5f5f5",
              boxShadow: "0 8px 16px rgba(0,0,0,0.3)",
            }}
          >
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Paper style={{ padding: "16px" }}>
                  <Typography variant="h6" gutterBottom>
                    Add New Code
                  </Typography>
                  <Formik
                    initialValues={{
                      code: "",
                      tag: "",
                      quota: 0,
                      startDate: null,
                      endDate: null,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { resetForm }) => {
                      handleAddCode(values);
                      resetForm();
                    }}
                  >
                    {({
                      handleChange,
                      handleBlur,
                      values,
                      setFieldValue,
                      errors,
                      touched,
                    }) => (
                      <Form>
                        <Field
                          as={TextField}
                          name="code"
                          label="Code"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.code}
                          error={touched.code && Boolean(errors.code)}
                          helperText={touched.code && errors.code}
                        />
                        <Field
                          as={TextField}
                          name="tag"
                          label="Tag"
                          fullWidth
                          margin="normal"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.tag}
                          error={touched.tag && Boolean(errors.tag)}
                          helperText={touched.tag && errors.tag}
                        />
                        <Field
                          as={TextField}
                          name="quota"
                          label="Quota"
                          fullWidth
                          type="number"
                          margin="normal"
                          variant="outlined"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.quota}
                          error={touched.quota && Boolean(errors.quota)}
                          helperText={touched.quota && errors.quota}
                        />

                        {/* Start Date and End Date in the same row */}
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div style={{ marginBottom: "16px" }}>
                              <label>Start Date</label>
                              <DatePicker
                                selected={values.startDate}
                                onChange={(date: Date) =>
                                  setFieldValue("startDate", date)
                                }
                                dateFormat="yyyy/MM/dd"
                                customInput={
                                  <TextField fullWidth variant="outlined" />
                                }
                              />
                              {touched.startDate && errors.startDate ? (
                                <div style={{ color: "red" }}>
                                  {errors.startDate}
                                </div>
                              ) : null}
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div style={{ marginBottom: "16px" }}>
                              <label>End Date</label>
                              <DatePicker
                                selected={values.endDate}
                                onChange={(date: Date) =>
                                  setFieldValue("endDate", date)
                                }
                                dateFormat="yyyy/MM/dd"
                                customInput={
                                  <TextField fullWidth variant="outlined" />
                                }
                              />
                              {touched.endDate && errors.endDate ? (
                                <div style={{ color: "red" }}>
                                  {errors.endDate}
                                </div>
                              ) : null}
                            </div>
                          </Grid>
                        </Grid>

                        {codeUpdateLoading ? (
                          <Container>
                            <CircularProgress />
                          </Container>
                        ) : (
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "16px" }}
                          >
                            Add Code
                          </Button>
                        )}
                      </Form>
                    )}
                  </Formik>
                </Paper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Paper
                  style={{
                    padding: "16px",
                    maxHeight: 600,
                    overflowY: "scroll",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h6" gutterBottom>
                      Discount Codes
                    </Typography>
                    {deleteLoading ? (
                      <Container>
                        <CircularProgress />
                      </Container>
                    ) : null}
                  </Box>
                  {codes.map((code) => (
                    <Box
                      key={code._id}
                      sx={{
                        marginBottom: "16px",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="subtitle1">
                          Code: {code.code}
                          {" ,"} Tag: {code.tag}
                          {" ,"} Quota: {code.quota ? code.quota : ""}
                          {" ,"} Start Date: {code?.startDate?.toString()}
                          {" ,"} End Date: {code?.endDate?.toString()}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteCode(code._id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
                </Paper>
              </Grid>
            </Grid>
          </Paper>
        )}
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "#1976d2", // Use primary color from the theme
              color: "white", // Set text color to white
            },
          }}
        />
      </Container>
    </>
  );
};

export default DiscountCode;

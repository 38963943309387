import React, { useState } from "react";
import {
  Typography,
  Box,
  Button,
  CircularProgress,
  Container,
} from "@mui/material";

const ResultsViewer = ({
  results,
  loading,
}: {
  results: any[];
  loading: boolean;
}) => {
  const [filter, setFilter] = useState<"all" | "success" | "fail">("all");
  const [expanded, setExpanded] = useState<string | null>(null);

  const filteredResults = results.filter((result) => {
    if (filter === "success") return result.success;
    if (filter === "fail") return !result.success;
    return true;
  });

  return (
    <Container>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Keep the filter buttons always visible */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 2,
              marginBottom: 2,
            }}
          >
            <Button
              variant={filter === "all" ? "contained" : "outlined"}
              onClick={() => setFilter("all")}
            >
              All
            </Button>
            <Button
              variant={filter === "success" ? "contained" : "outlined"}
              onClick={() => setFilter("success")}
            >
              Success
            </Button>
            <Button
              variant={filter === "fail" ? "contained" : "outlined"}
              onClick={() => setFilter("fail")}
            >
              Fail
            </Button>
          </Box>

          {/* Conditional rendering for results */}
          {results.length === 0 ? (
            <Typography sx={{ textAlign: "center", marginTop: 4 }} variant="h6">
              No Response Available
            </Typography>
          ) : filteredResults.length === 0 ? (
            <Typography sx={{ textAlign: "center", marginTop: 4 }} variant="h6">
              {filter === "success"
                ? "No Success Available"
                : "No Failures Available"}
            </Typography>
          ) : (
            filteredResults.map((result, index) => (
              <Box
                key={index}
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  marginBottom: 2,
                  padding: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>
                    Name: {result.name} | Success:{" "}
                    {result.success ? "Yes" : "No"}
                  </Typography>
                  <Button
                    size="small"
                    onClick={() =>
                      setExpanded(expanded === result.name ? null : result.name)
                    }
                  >
                    {expanded === result.name ? "Collapse" : "Expand"}
                  </Button>
                </Box>
                {expanded === result.name && (
                  <Box
                    sx={{
                      marginTop: 1,
                      padding: 1,
                      backgroundColor: "#f9f9f9",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="body2">
                      Response: {JSON.stringify(result.response, null, 2)}
                    </Typography>
                  </Box>
                )}
              </Box>
            ))
          )}
        </>
      )}
    </Container>
  );
};

export default ResultsViewer;

import {
  Modal,
  Box,
  Paper,
  Typography,
  Grid,
  Divider,
  IconButton,
  Snackbar,
  Container,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  CampaignInsight,
  CampaignInsightsInterface,
  CampaignType,
} from "../../../interface/Campaign";
import { useEffect, useState } from "react";
import { campaignService } from "../../../services/campaign.service";
import ExpandableResponses from "../UserResponseExpand";
export default function CampaignInsights({
  insightsModalOpen,
  setInsightsModalOpen,
  currentCampaign,
}: CampaignInsightsInterface) {
  const [interaction, setInteraction] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [campaign, setCampaign] = useState<CampaignType | null>(
    currentCampaign
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    async function getInteractions() {
      try {
        setDataLoading(true);
        // Use Promise.all to fetch interactions and insights concurrently
        const [interactions, interactionsInsights] = await Promise.all([
          campaignService.getInteractions(campaign?.uuid), // Fetch interactions
          campaignService.getInteractionsInsights(campaign?.uuid), // Fetch insights for the interactions
        ]);

        if (interactions.success) {
          setSnackbarOpen(true);
          setSnackbarMessage("Details have been fetched");

          if (interactions.data && interactions.data.length > 0) {
            // Format interactions data and match it with insights
            let formattedData = interactions.data.map((item: any) => {
              // Find the corresponding interaction insight once before mapping
              const interactionInsight =
                interactionsInsights.insightDetails.reduce(
                  (acc: any, insight: any) => {
                    if (insight.interaction_id === item.uuid) {
                      acc = insight;
                    }
                    return acc;
                  },
                  null
                );

              // Return the formatted data, adding options and totalResponses from insights
              return {
                ...item,
                input_fields: JSON.parse(item.input_fields), // Parsing input fields
                options: interactionInsight?.options || [], // Add options if found
                totalResponses: interactionInsight?.totalResponses || 0, // Add totalResponses if found
                responses: interactionInsight?.responses || [], // Add responses if found
                totalDelivered: interactionInsight?.totalDelivered || 0, // Add totalDelivered if found
              };
            });

            // Set the state with the formatted data
            setInteraction(formattedData);
          }
        } else {
          setSnackbarOpen(false);
          setSnackbarMessage("Some error has occurred");
        }
      } catch (error: any) {
        setSnackbarOpen(false);
        setSnackbarMessage(`Some error has happened ${error.message}`);
      } finally {
        setDataLoading(false);
      }
    }
    getInteractions();
  }, [currentCampaign]);
  const handleClose = () => {
    setInsightsModalOpen(false);
  };

  function handleCloseSnackbar() {
    setSnackbarOpen(!snackbarOpen);
  }

  return (
    <>
      <Modal
        open={insightsModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          p={2}
        >
          <Paper
            elevation={3}
            sx={{
              position: "relative",
              width: "100%",
              maxWidth: 800,
              padding: 4,
              borderRadius: 2,
              bgcolor: "#f9f9f9",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Close Button */}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                color: "gray",
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-title" variant="h5" fontWeight="bold">
              Campaign Results {campaign ? `(${campaign.name})` : ""}
            </Typography>

            {/* Content */}
            {dataLoading ? (
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <Box
                sx={{
                  maxHeight: "70vh",
                  overflowY: "auto",
                  mt: 3,
                  pr: 1,
                }}
              >
                {interaction.length === 0 ? (
                  <Typography variant="h6" align="center">
                    No interaction available
                  </Typography>
                ) : (
                  interaction.map((item: CampaignInsight, index: any) => (
                    <Paper
                      key={index}
                      elevation={2}
                      sx={{
                        padding: 2,
                        marginBottom: 3,
                        borderRadius: 2,
                        bgcolor: "white",
                        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      {/* <Typography variant="h6" fontWeight="bold" gutterBottom>
        {item.interaction}
      </Typography> */}
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <strong>Type:</strong> {item.type}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <strong>Title:</strong>{" "}
                            {item.input_fields && item.input_fields.title
                              ? item.input_fields.title
                              : ""}
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography variant="body1">
                            <strong>Content:</strong>{" "}
                            {item.input_fields && item.input_fields.body
                              ? item.input_fields.body
                              : ""}
                          </Typography>
                        </Grid>
                        {item.totalDelivered !== undefined && (
                          <Grid item xs={6}>
                            <Typography variant="body1">
                              <strong>Total Deliveries:</strong>{" "}
                              {item.totalDelivered}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <Typography
                            variant="body1"
                            style={{ textAlign: "right" }}
                          >
                            <strong>Total Responses:</strong>{" "}
                            {item.totalResponses}
                          </Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={4}
                        style={{
                          display: "flex",
                          flexDirection: "column", // Changed to column to stack items vertically
                          justifyContent: "flex-start",
                          alignItems: "flex-start", // Align to the left
                        }}
                      >
                        {/* Options */}
                        {item.options && item.options.length > 0 && (
                          <Box mb={2}>
                            <Typography variant="h6" gutterBottom>
                              Options
                            </Typography>
                            {item.options.map((option: any, idx: any) => (
                              <Typography key={idx} variant="body1">
                                <strong>Option {idx + 1}:</strong>{" "}
                                {/* Display Option 1, Option 2, etc. */}
                                {option.label}: {option.percentage}%
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Grid>
                      {/* Responses */}
                      <ExpandableResponses responses={item.responses} />
                    </Paper>
                  ))
                )}
              </Box>
            )}
          </Paper>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
}

import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";
import { additionalService } from "../../services/additional.service";

interface FreeTextFormProps {
  onClose: () => void;
  onSave: (data: any) => void;
  campaignId: string | null;
  type: string | null;
  sequence: any;
  initialData?: {
    uuid: string;
    campaign_id: string;
    sequence: number;
    type: string;
    input_fields: {
      title: string;
      body?: string;
      imageUrl?: string | null;
      inputBoxPlaceholder?: string;
    };
  };
  editingInteractionId: any;
  onEdit: (data: any) => void;
}
type FormDataType =
  | {
      title: string;
      body: string;
      image: File | null;
      imageUrl: string | null;
      imageName: string | null;
    }
  | {
      uuid: string;
      title: string;
      body: string;
      image: File | null;
      imageUrl: string | null;
      imageName: string | null;
    };

const FreeTextForm: React.FC<FreeTextFormProps> = ({
  onClose,
  onSave,
  initialData,
  campaignId,
  type,
  sequence,
  editingInteractionId,
  onEdit,
}) => {
  const [formData, setFormData] = useState(() => {
    const baseFormData = {
      title: initialData?.input_fields?.title || "",
      body: initialData?.input_fields?.body || "",
      imageUrl: initialData?.input_fields?.imageUrl || null,
      image: null as File | null, // Handle new image file,
      imageName: initialData?.input_fields?.imageUrl
        ? initialData.input_fields.imageUrl.split("/").pop() || ""
        : null, // Extract image name from the URL
      inputBoxPlaceholder: initialData?.input_fields?.inputBoxPlaceholder || "",
    };

    // Conditionally include the `uuid` key if it exists
    return initialData?.uuid
      ? { ...baseFormData, uuid: initialData.uuid }
      : baseFormData;
  });

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  // Memoized image name derived from initialData to prevent unnecessary recalculations
  const imageName = useMemo(() => {
    return initialData?.input_fields?.imageUrl
      ? initialData.input_fields.imageUrl.split("/").pop() || ""
      : null;
  }, [initialData?.input_fields?.imageUrl]);

  const handleImageChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        setFormData((prevFormData) => ({
          ...prevFormData,
          image: file,
          imageName: file.name, // Set image name
        }));
      }
    },
    []
  );

  const handleImageClear = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      image: null,
      imageName: null,
      imageUrl: null, // Clear image URL if removing image
    }));
  };
  const handleInputChange = useCallback(
    (field: string, value: string) => {
      setFormData((prev) => ({
        ...prev,
        [field]: value,
      }));

      if (error) setError(null); // Clear error when user starts typing
    },
    [error]
  );

  const handleSubmit = useCallback(async () => {
    if (!formData.title) {
      setError("Title is required.");
      return;
    }

    setLoading(true);
    let imageUrl = formData.imageUrl;

    try {
      if (formData.image) {
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }

      onSave({
        campaign_id: campaignId, // Keep existing campaign_id
        sequence: sequence, // Keep existing sequence
        type: type, // Keep existing type
        input_fields: {
          title: formData.title,
          body: formData.body,
          imageUrl, // Either the new or existing image URL
          inputBoxPlaceholder: formData.inputBoxPlaceholder,
        },
      });

      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  }, [formData, campaignId, sequence, type, onSave, onClose]);

  const handleEdit = useCallback(async () => {
    setLoading(true);

    let imageUrl = formData.imageUrl; // Use existing image URL if no new image is uploaded

    try {
      if (formData.image) {
        // If a new image is selected, upload it
        imageUrl = await additionalService.uploadImageOnAzure(formData.image);
      }
      if ("uuid" in formData) {
        // Save the form data with updated values
        onEdit({
          uuid: formData.uuid,
          index: editingInteractionId,
          campaign_id: campaignId,
          sequence: sequence,
          type: type,
          input_fields: {
            title: formData.title,
            body: formData.body,
            imageUrl,
            inputBoxPlaceholder: formData.inputBoxPlaceholder,
          },
        });
      }
      onClose();
    } catch (error) {
      console.error("Error during submission:", error);
    } finally {
      setLoading(false);
    }
  }, [
    formData,
    campaignId,
    sequence,
    type,
    onEdit,
    onClose,
    editingInteractionId,
  ]);

  return (
    <Box sx={{ width: 400, mx: "auto", mt: 4 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Free Text Form
      </Typography>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.title}
        onChange={(e) => handleInputChange("title", e.target.value)}
      />
      <TextField
        label="Body"
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        sx={{ mb: 2 }}
        value={formData.body}
        placeholder={formData.inputBoxPlaceholder}
        onChange={(e) => handleInputChange("body", e.target.value)}
      />
      <Box sx={{ mb: 2 }}>
        <input
          accept="image/*"
          id="image-upload"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageChange}
        />
        <label htmlFor="image-upload">
          <Button variant="contained" component="span">
            Browse Image
          </Button>
        </label>
        {formData.imageName && (
          <Box sx={{ display: "flex", alignItems: "center", mt: 1 }}>
            <Typography variant="body2" sx={{ mr: 1 }}>
              {formData.imageName}
            </Typography>
            <IconButton onClick={handleImageClear} size="small">
              <ClearIcon />
            </IconButton>
          </Box>
        )}
      </Box>
      <TextField
        label="Body Input Placeholder"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={formData.inputBoxPlaceholder}
        onChange={(e) =>
          handleInputChange("inputBoxPlaceholder", e.target.value)
        }
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          color="secondary"
          sx={{ mr: 1 }}
          onClick={onClose}
        >
          Cancel
        </Button>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {initialData ? (
              <Button variant="contained" color="primary" onClick={handleEdit}>
                Update
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                Add
              </Button>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default React.memo(FreeTextForm);

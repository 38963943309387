import { config } from "../shared/constants/config";

import { fetchWrapper } from "../helpers";

import { accountService } from "./account.service";
const baseUrl = `${config.whatsAppBackendApiUrl}/api`;
export const whatsappService = { sendWhatsAppMessage, getWhatsAppMessage };

//// Function for sending the whats app message -----------------------------------------------/
async function sendWhatsAppMessage(data: any) {
  return fetchWrapper.post(`${baseUrl}/send-message`, {
    ...data,
  });
}

//// Function for getting the whats app message -----------------------------------------------/
async function getWhatsAppMessage() {
  return fetchWrapper.get(`${baseUrl}/get-whatsapp-chats`);
}

import React, { useState } from "react";
import { Box, Typography, IconButton, Collapse, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserResponse } from "../../interface/Campaign";

interface ResponseExpandable {
  responses: UserResponse[];
}

const ExpandableResponses: React.FC<ResponseExpandable> = ({ responses }) => {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event: React.MouseEvent) => {
    event.stopPropagation(); // Prevent parent click handlers from firing
    setExpanded((prev) => !prev);
  };

  return (
    <Box>
      {/* Header Section */}
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={handleToggle}
      >
        <Typography variant="body1" fontWeight="bold" gutterBottom>
          Responses:
        </Typography>
        <IconButton
          size="small"
          style={{ transform: expanded ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <ExpandMoreIcon />
        </IconButton>
      </Box>

      {/* Expandable Section */}
      <Collapse in={expanded}>
        <Box
          style={{ maxHeight: "200px", overflowY: "auto", padding: "8px 0" }}
        >
          {responses && responses.length > 0 ? (
            <>
              {/* Headings Row */}
              <Grid container spacing={1} style={{ marginBottom: "8px" }}>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    User ID
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="subtitle2" fontWeight="bold">
                    Content
                  </Typography>
                </Grid>
              </Grid>

              {/* Response Rows */}
              {responses.map((response, idx) => (
                <Grid container spacing={1} key={idx}>
                  <Grid item xs={6}>
                    <Typography variant="body2">{response.userId}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="body2">{response.content}</Typography>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            <Typography style={{ textAlign: "center" }} variant="body2">
              No responses available
            </Typography>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default ExpandableResponses;

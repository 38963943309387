/** @format */
import { EachDayStepsInterface } from "../interface/Steps";
export const stepsUtilsFunctions = {
  getSteps,
};

//// Function for getting the steps of a current date----------------------/
async function getSteps(
  stepsData: any,
  currentDate: Date
): Promise<EachDayStepsInterface> {
  try {
    // Extract year, month, and day from currentDate
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const currentDay = currentDate.getDate();

    // Find the step data for the current day
    const currentDayStepData = stepsData.find((step: any) => {
      const stepsDateTime = new Date(step.dateTime || step.endDateTime); // Use dateTime if exists, else endDateTime
      return (
        currentYear === stepsDateTime.getFullYear() &&
        currentMonth === stepsDateTime.getMonth() &&
        currentDay === stepsDateTime.getDate()
      );
    });

    if (currentDayStepData) {
      const stepsDateTime = new Date(
        currentDayStepData.dateTime || currentDayStepData.endDateTime
      ); // Fallback to endDateTime
      const options: Intl.DateTimeFormatOptions = {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };
      const stepsTime = stepsDateTime.toLocaleTimeString([], options);
      return { steps: currentDayStepData.steps, date: stepsTime };
    } else {
      return { steps: 0, date: "" }; // Return default if no data is found
    }
  } catch (error) {
    console.error("Error in getSteps:", error);
    return { steps: 0, date: "" }; // Return default in case of error
  }
}

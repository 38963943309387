import { fetchWrapper } from "../helpers";
import { GetInteractionsInsightsResponse } from "../interface/Campaign";
import { config } from "../shared/constants/config";
const baseUrl = `${config.apiUrl}/api`;
export const campaignService = {
  createNewCampaign,
  getAllCampaign,
  addAudiences,
  deleteCampaign,
  getAllAudiences,
  createDuplicateCampaign,
  getCampaignEvents,
  addEventToCampaign,
  fetchAllEventName,
  getInteractions,
  getInteractionsInsights,
};

/// Function For adding new campagins ------------------------------------/
async function createNewCampaign(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/create-campaign`, {
    ...data,
  });
}
async function fetchAllEventName() {
  try {
    const response = await fetch(
      "https://reshapestorage.blob.core.windows.net/reshape-public/campaignEvents.json"
    );

    const finalResponse = await response.json();

    return finalResponse.events;
  } catch (error) {
    return [];
  }
}
/// Function For adding events to campaign ------------------------------------/
async function addEventToCampaign(
  campaign_id: any,
  eventName: string
): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/add-event-campaign`, {
    campaign_id: campaign_id,
    eventName: eventName,
  });
}
/// Function For adding duplicate campagins ------------------------------------/
async function createDuplicateCampaign(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/create-duplicatecampaign`, {
    ...data,
  });
}
// Funciton for deleting the campaign -----------------------------------/
async function deleteCampaign(campaignId: string) {
  return fetchWrapper.delete(
    `${baseUrl}/delete-campaign?campaignId=${campaignId}`
  );
}

async function addAudiences(data: any): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.post(`${baseUrl}/add-audiences`, {
    data,
  });
}
/// Function For adding new campagins ------------------------------------/
async function getAllCampaign(): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(`${baseUrl}/get-campaigns`);
}

/// Function For getting campaign events ------------------------------------/
async function getCampaignEvents(campaign_id: string): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-campaigneventname?campaign_id=${campaign_id}`
  );
}

//// Funciton for getting all the audiences of an campaign -----------------/
async function getAllAudiences(campaign_id: string): Promise<{
  data: any;
  message: string;
  success: boolean;
}> {
  return fetchWrapper.get(
    `${baseUrl}/get-audiences?campaign_id=${campaign_id}`
  );
}

/// Funciton for getting all the interactions of a campaign -----------------/
async function getInteractions(campaign_id: string) {
  return fetchWrapper.get(
    `${baseUrl}/get-interactions?campaign_id=${campaign_id}`
  );
}

/// Function for getting all the insights of the interactions ---------------/

// Function signature
async function getInteractionsInsights(
  campaign_id: string
): Promise<GetInteractionsInsightsResponse> {
  return fetchWrapper.get(
    `${baseUrl}/get-interactionsinsights?campaign_id=${campaign_id}`
  );
}

/** @format */

import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Container,
  Grid,
  TextField,
  Button,
  Snackbar,
  Box,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { Formik, Form, Field } from "formik";

import { accountService } from "../../services/account.service";
import { settingsService } from "../../services/settings.service";

function AppFeatures() {
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState<any>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    const fetchAppFeatures = async () => {
      try {
        const storageNameResponse = await accountService.getStorageName();
        const response = await fetch(
          `https://${storageNameResponse.storageName}.blob.core.windows.net/reshape-public/app-features.json`
        );
        const data = await response.json();
        setInitialValues(data);
      } catch (error) {
        console.error("Failed to fetch app features:", error);
        setSnackbarMessage(
          "Failed to fetch app features. Please try again later."
        );
        setSnackbarOpen(true);
      } finally {
        setLoading(false);
      }
    };

    fetchAppFeatures();
  }, []);

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    setSubmitLoading(true);
    try {
      const transformedValues = Object.keys(values).reduce((acc: any, key) => {
        const value = values[key];
        if (typeof value === "string" && value.includes(",")) {
          acc[key] = value.split(",").map((item) => item.trim());
        } else {
          acc[key] = value;
        }
        return acc;
      }, {});

      const response = await settingsService.updateAppFeatures(
        transformedValues
      );
      if (response.success) {
        setSnackbarMessage("App features has been updated");
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error("Failed to update app features:", error);
      setSnackbarMessage("Failed to update app features. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const renderField = (key: string, value: any, setFieldValue: any) => {
    if (typeof value === "boolean") {
      return (
        <FormControlLabel
          control={
            <Field name={key}>
              {({ field }: any) => (
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(event) => setFieldValue(key, event.target.checked)}
                />
              )}
            </Field>
          }
          label={key.replace(/_/g, " ")}
        />
      );
    } else if (Array.isArray(value)) {
      return (
        <Field name={key}>
          {({ field }: any) => (
            <TextField
              {...field}
              label={key.replace(/_/g, " ")}
              variant="outlined"
              fullWidth
              margin="none"
              style={{ marginBottom: 8 }}
              helperText="Separate values with commas"
            />
          )}
        </Field>
      );
    } else if (typeof value === "number") {
      return (
        <Field
          as={TextField}
          type="number"
          name={key}
          label={key.replace(/_/g, " ")}
          variant="outlined"
          fullWidth
          margin="none"
          style={{ marginBottom: 8 }}
        />
      );
    } else if (typeof value === "string") {
      return (
        <Field
          as={TextField}
          name={key}
          label={key.replace(/_/g, " ")}
          variant="outlined"
          fullWidth
          margin="none"
          style={{ marginBottom: 8 }}
        />
      );
    } else {
      return null;
    }
  };

  return (
    <Box p={3} style={{ backgroundColor: "#f0f0f0" }}>
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: "700" }}>
          App Features
        </Typography>
      </Container>
      {loading ? (
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Grid container spacing={2}>
                {Object.entries(initialValues).map(([key, value]) => (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={key}
                    style={{ marginBottom: 8 }}
                  >
                    {renderField(key, value, setFieldValue)}
                  </Grid>
                ))}
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 16,
                  }}
                >
                  {submitLoading ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      disabled={isSubmitting}
                    >
                      Submit
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </Box>
  );
}

export default AppFeatures;

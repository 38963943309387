import { accountService } from "../services";
import imageCompression from "browser-image-compression";

const createFileName = (extension: any) => {
  const now = new Date();
  return `${now.getTime()}-file.${extension}`;
};
const compressImageToMaxSize = async (file: File, maxSizeKB: number) => {
  const options = {
    maxSizeMB: maxSizeKB / 1024, // Convert KB to MB
    maxWidthOrHeight: 1920, // Resize dimensions if needed
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);

    // Ensure the file is within the required size
    if (compressedFile.size / 1024 > maxSizeKB) {
      throw new Error("Unable to compress below the maximum size");
    }

    return compressedFile;
  } catch (error) {
    console.error("Compression failed:", error);
    throw error;
  }
};
// Replace this with your actual file upload logic

const uploadFileInAzure = async (
  file: File,
  fileName: string,
  role: string
) => {
  try {
    const user = accountService.userValue;

    let fileType = "";

    if (role === "admin") {
      fileType += "support-chat-files";
    } else {
      fileType += "trainer-chat-files";
    }

    const storageNameResponse = await accountService.getStorageName();
    console.log("this is storagename response");
    console.log(storageNameResponse);

    let storageName = storageNameResponse.storageName;
    let sasToken = storageNameResponse.sasToken;

    // Compress the file (if it's an image) and enforce max size
    let compressedFile = file;
    if (file.type.startsWith("image/")) {
      compressedFile = await compressImageToMaxSize(file, 400); // Compress to max 400KB
    }

    const containerUrl = `https://${storageName}.blob.core.windows.net/user-data/${user.id}/${fileType}/${fileName}?${sasToken}`;

    const response = await fetch(containerUrl, {
      method: "PUT",
      body: compressedFile,
      headers: {
        "x-ms-blob-type": "BlockBlob",
      },
    });

    if (response.status === 201) {
      return response.url; // URL of the uploaded file
    } else {
      console.error("Upload failed with status:", response.status);
      return null;
    }
  } catch (e) {
    console.error("Error during upload:", e);
    return null;
  }
};
async function uploadChatImage(
  file: File,
  fileName: string,
  role: string,
  sasToken: string,
  storageName: string
) {
  try {
    const user = accountService.userValue;

    let fileType = "";

    if (role === "admin") {
      fileType += "support-chat-files";
    } else {
      fileType += "trainer-chat-files";
    }

    // Compress the file (if it's an image) and enforce max size
    let compressedFile = file;
    if (file.type.startsWith("image/")) {
      compressedFile = await compressImageToMaxSize(file, 400); // Compress to max 400KB
    }

    const containerUrl = `https://${storageName}.blob.core.windows.net/user-data/${user.id}/${fileType}/${fileName}?${sasToken}`;

    const response = await fetch(containerUrl, {
      method: "PUT",
      body: compressedFile,
      headers: {
        "x-ms-blob-type": "BlockBlob",
      },
    });

    if (response.status === 201) {
      return response.url; // URL of the uploaded file
    } else {
      console.error("Upload failed with status:", response.status);
      return null;
    }
  } catch (e) {
    console.error("Error during upload:", e);
    return null;
  }
}
export async function uploadFile(
  file: any,
  userId: any,
  role: string,
  sasToken: string,
  storageAccount: string
) {
  try {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    const fileName = createFileName(fileExtension);

    const uploadedUrl = await uploadChatImage(
      file,
      fileName,
      role,
      sasToken,
      storageAccount
    );

    if (uploadedUrl) {
      return uploadedUrl;
    }
  } catch (error) {
    return { uploadedUrl: "", success: false };
  }
}

/// Function For showing images on the support chat in a proper way
export function chunkArray(array: any, size: any) {
  return array.reduce((acc: any, val: any, i: any) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);
    return acc;
  }, []);
}

// Function to check if the URL is a video
export function isVideoUrl(url: any) {
  const mp4Index = url.indexOf(".mp4");
  const queryIndex = url.indexOf("?");
  return mp4Index > -1 && (queryIndex === -1 || mp4Index < queryIndex);
}

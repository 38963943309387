/** @format */

const dev = Object.freeze({
  apiUrl: "https://reshape-support-backend.azurewebsites.net",
  //apiUrl: "http://localhost:7071",
  //whatsAppBackendApiUrl: "http://localhost:8080",
  whatsAppBackendApiUrl:
    "https://reshape-whatsapp-api-backend.azurewebsites.net",
});

// export const config = process.env.NODE_ENV === "development" ? dev : prod;
export const config = dev;

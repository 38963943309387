import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Switch,
  FormControlLabel,
  Box,
  Container,
  Modal,
  Paper,
  IconButton,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import TopAppBar from "../modules/TopAppBar";
import { abTestService } from "../services/abtesting.service";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useLocation } from "react-router-dom";
export interface Variant {
  _id?: string;
  identifier?: string;
  allocation?: number | null;
  variables?: any;
  description?: string;
}

export default function ExperimentDetails() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("experiment_id"); // Extract `_id` from query string
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [updateButtonLoading, setUpdateButtonLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentEditingIndex, setCurrentEditingIndex] = useState<number | null>(
    null
  );
  const [dataLoading, setDataLoading] = useState(false);
  const [experiment, setExperiment] = useState<any>({
    _id: "",
    experimentName: "",
    status: false,
    identifier: "",
    variants: [],
  });
  const [deleteLoading, setDeleteLoading] = useState<number | null>(null);
  const [editingVariant, setEditingVariant] = useState<Variant>({
    identifier: "",
    allocation: 0,
    variables: "",
    description: "",
  });
  async function fetchExperiment(experiment_id: string) {
    try {
      const response = await abTestService.getSingleExperimentDetails(
        experiment_id
      );

      if (response.success) {
        setExperiment(response.variantData);
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      } else {
        setSnackbarOpen(false);
        setSnackbarMessage(response.message);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(`Some error has happened ${error.message}`);
    } finally {
      setDataLoading(false);
    }
  }
  useEffect(() => {
    if (id) {
      setDataLoading(true);
      fetchExperiment(id);
    }
  }, []);
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOpenModal = (index: number) => {
    setCurrentEditingIndex(index);
    setEditingVariant({ ...experiment.variants[index] });
    setIsModalOpen(true);
  };

  const handleSaveVariant = () => {
    let allow = false;

    if (experiment.variants && experiment.variants.length > 0) {
      let variantData = experiment.variants;
      for (let i = 0; i < variantData.length; i++) {
        try {
          if (
            variantData[i].identifier == editingVariant.identifier &&
            i !== currentEditingIndex
          )
            allow = true;
        } catch (error) {}
      }
    }

    if (allow === false) {
      const updatedVariants = [...experiment.variants];
      if (currentEditingIndex !== null) {
        updatedVariants[currentEditingIndex] = editingVariant;
      } else {
        updatedVariants.push(editingVariant);
      }
      setExperiment({ ...experiment, variants: updatedVariants });
      setIsModalOpen(false);
      setEditingVariant({
        identifier: "",
        allocation: 0,
        variables: "",
        description: "",
      });
      setCurrentEditingIndex(null);
    } else {
      setSnackbarOpen(true);
      setSnackbarMessage("Identifier can't be duplicated");
    }
  };
  // Helper function to safely parse JSON strings
  function safelyParseJSON(jsonString: string) {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      console.error("Failed to parse JSON:", e);
      return {}; // Default to an empty object on parse failure
    }
  }
  async function updateExperiment() {
    try {
      setUpdateButtonLoading(true);
      // Preprocess the experiment object to safely handle empty variants or missing variables
      const processedExperiment = {
        ...experiment,
        variants: Array.isArray(experiment.variants)
          ? experiment.variants.map((variant: any) => ({
              ...variant,
              variables:
                variant.variables && typeof variant.variables === "string"
                  ? safelyParseJSON(variant.variables)
                  : variant.variables || {}, // Default to an empty object if variables don't exist
            }))
          : [], // Default to an empty array if variants is not an array
      };

      // Send the processedExperiment to the API
      const response = await abTestService.updateExperiment(
        processedExperiment
      );

      if (response.success) {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
        setExperiment(response.experimentData);
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(response.message);
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setUpdateButtonLoading(false);
    }
  }

  async function onDelete(variantDetails: Variant, index: any) {
    try {
      setDeleteLoading(index);
      if (variantDetails._id) {
        const response = await abTestService.deleteVariant(
          variantDetails._id,
          experiment._id
        );
        if (response.success) {
          const updatedVariants = [...experiment.variants];
          updatedVariants.splice(index, 1); // Remove the variant at the given index
          setExperiment({ ...experiment, variants: updatedVariants });
          setSnackbarOpen(true);
          setSnackbarMessage("Variant removed successfully.");
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(
            `Some error has happened unable to remove the variant ${response.message}`
          );
        }
      } else {
        // Remove the variant based on index for local-only changes
        const updatedVariants = [...experiment.variants];
        updatedVariants.splice(index, 1); // Remove the variant at the given index
        setExperiment({ ...experiment, variants: updatedVariants });
        setSnackbarOpen(true);
        setSnackbarMessage("Variant removed successfully.");
      }
    } catch (error: any) {
      setSnackbarOpen(true);
      setSnackbarMessage(error.message);
    } finally {
      setDeleteLoading(null);
    }
  }
  return (
    <>
      <TopAppBar />
      {dataLoading ? (
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Container>
      ) : (
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
            padding: 2,
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: 1200,
              padding: 3,
              border: "1px solid #3f51b5",
              borderRadius: 2,
              backgroundColor: "#ffffff",
              boxShadow: 3,
            }}
          >
            <Typography
              variant="h5"
              sx={{ marginBottom: 2, fontWeight: "bold", textAlign: "center" }}
            >
              Experiment Details
            </Typography>

            <TextField
              label="Experiment Name"
              variant="outlined"
              fullWidth
              value={experiment.experimentName}
              onChange={(e) =>
                setExperiment({ ...experiment, experimentName: e.target.value })
              }
              sx={{ marginBottom: 2 }}
            />

            <TextField
              label="Identifier"
              variant="outlined"
              fullWidth
              value={experiment.identifier}
              onChange={(e) =>
                setExperiment({ ...experiment, identifier: e.target.value })
              }
              sx={{ marginBottom: 2 }}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={experiment.status}
                  onChange={() =>
                    setExperiment({ ...experiment, status: !experiment.status })
                  }
                />
              }
              label="Status"
              sx={{ marginBottom: 2 }}
            />

            <Box
              sx={{
                maxHeight: 200,
                overflowY: "auto",
                border: "1px solid #ddd",
                borderRadius: 1,
                padding: 2,
                marginBottom: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              {experiment &&
                experiment.variants &&
                experiment.variants.length > 0 &&
                experiment.variants
                  .sort(
                    (a: any, b: any) =>
                      (b.allocation || 0) - (a.allocation || 0)
                  ) // Sort by allocation in descending order
                  .map((variant: any, index: number) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <Button
                        variant="outlined"
                        fullWidth
                        onClick={() => handleOpenModal(index)}
                      >
                        {`Variants ${index + 1} ${
                          variant.allocation ? `(${variant.allocation})` : ""
                        }`}
                      </Button>

                      {deleteLoading === index ? (
                        <Container>
                          <CircularProgress />
                        </Container>
                      ) : (
                        <IconButton
                          aria-label="delete"
                          onClick={() => onDelete(variant, index)}
                          sx={{ color: "red" }} // Optional styling
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Box>
                  ))}
            </Box>

            <Button
              variant="outlined"
              sx={{ marginBottom: 2, width: "100%" }}
              onClick={() => {
                setCurrentEditingIndex(null);
                setEditingVariant({
                  identifier: "",
                  allocation: 0,
                  variables: "",
                  description: "",
                });
                setIsModalOpen(true);
              }}
            >
              Add Variant
            </Button>

            {updateButtonLoading ? (
              <Container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </Container>
            ) : (
              <Button
                variant="contained"
                onClick={() => updateExperiment()}
                sx={{ width: "100%" }}
              >
                Save Experiment
              </Button>
            )}
          </Box>
        </Container>
      )}

      <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Paper
          sx={{
            width: "100%",
            maxWidth: 800,
            margin: "auto",
            marginTop: "10vh",
            padding: 3,
            borderRadius: 2,
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => setIsModalOpen(false)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            {currentEditingIndex !== null
              ? "Edit Variable"
              : "Add New Variable"}
          </Typography>
          <TextField
            label="Identifier"
            variant="outlined"
            fullWidth
            value={editingVariant.identifier}
            onChange={(e) =>
              setEditingVariant({
                ...editingVariant,
                identifier: e.target.value,
              })
            }
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Allocation"
            type="number"
            variant="outlined"
            fullWidth
            value={
              editingVariant.allocation !== null &&
              editingVariant.allocation !== undefined
                ? editingVariant.allocation
                : ""
            }
            onChange={(e) =>
              setEditingVariant({
                ...editingVariant,
                allocation:
                  e.target.value === "" ? null : parseInt(e.target.value, 10),
              })
            }
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Variables"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={
              typeof editingVariant.variables === "object" &&
              editingVariant.variables !== null
                ? JSON.stringify(editingVariant.variables) // Convert to a single-line JSON string
                : editingVariant.variables || "" // Keep existing value if already a string
            }
            onChange={(e) => {
              try {
                setEditingVariant({
                  ...editingVariant,
                  variables: JSON.parse(e.target.value), // Parse the string back to an object
                });
              } catch {
                setEditingVariant({
                  ...editingVariant,
                  variables: e.target.value, // Keep as string if parsing fails
                });
              }
            }}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Description"
            variant="outlined"
            fullWidth
            multiline
            rows={3}
            value={editingVariant.description || ""}
            onChange={(e) =>
              setEditingVariant({
                ...editingVariant,
                description: e.target.value,
              })
            }
            sx={{ marginBottom: 2 }}
          />
          <Button
            variant="contained"
            onClick={handleSaveVariant}
            sx={{ width: "100%" }}
          >
            Save
          </Button>
        </Paper>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#1976d2",
            color: "white",
          },
        }}
      />
    </>
  );
}

import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Modal,
  Paper,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Container,
  IconButton,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag"; // Use Flag Icon for countries
import { additionalService } from "../services/additional.service";
import TopAppBar from "../modules/TopAppBar";

const WeeklyReportStat = () => {
  const [open, setOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalData, setModalData] = useState<string[]>([]);
  const [countryModalOpen, setCountryModalOpen] = useState(false);
  const [countryData, setCountryData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState<any[]>([]);

  useEffect(() => {
    async function fetchWeeklyReport() {
      try {
        setLoading(true);
        const response = await additionalService.fetchWeeklyReportStat();
        if (response.success) {
          setReportData(response.data);
        }
      } catch (error) {
        console.error("Error fetching weekly report stats:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchWeeklyReport();
  }, []);

  const handleOpenModal = (title: string, data: string[]) => {
    setModalTitle(title);
    setModalData(data);
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
    setModalTitle("");
    setModalData([]);
  };

  const handleOpenCountryModal = async (reportId: string) => {
    console.log("this is country modal");
    console.log(reportId);

    try {
      setLoading(true);
      const response = await additionalService.fetchCountryStats(reportId); // Pass reportId
      if (response.success) {
        setCountryData(response.data);
        setCountryModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching country stats:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseCountryModal = () => {
    setCountryModalOpen(false);
    setCountryData(null);
  };

  const formatDate = (date: string) => new Date(date).toLocaleDateString();

  return (
    <>
      <TopAppBar />

      <Box sx={{ p: 4, maxWidth: "80%", margin: "auto" }}>
        <Typography variant="h4" gutterBottom align="center">
          Weekly Report Statistics
        </Typography>
        {loading ? (
          <Container>
            <CircularProgress
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </Container>
        ) : (
          <TableContainer
            component={Paper}
            sx={{ borderRadius: 2, boxShadow: 2 }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">Report Date</TableCell>
                  <TableCell align="center">Total Users</TableCell>
                  <TableCell align="center">Eligible Users</TableCell>
                  <TableCell align="center">Success Users</TableCell>
                  <TableCell align="center">Failed Users</TableCell>
                  <TableCell align="center">Country Stats</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reportData.map((report, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {formatDate(report.reportDate)}
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleOpenModal("Total Users", report.totalUsers)
                        }
                      >
                        {report.totalUsers.length}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleOpenModal(
                            "Eligible Users",
                            report.totalEligibleUsers
                          )
                        }
                      >
                        {report.totalEligibleUsers.length}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleOpenModal("Success Users", report.successUsers)
                        }
                      >
                        {report.successUsers.length}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        onClick={() =>
                          handleOpenModal("Failed Users", report.failedUsers)
                        }
                      >
                        {report.failedUsers.length}
                      </Button>
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenCountryModal(report._id)}
                      >
                        <FlagIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        {/* Modal to display user IDs */}
        <Modal open={open} onClose={handleCloseModal}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              maxHeight: 500,
              overflowY: "auto",
              p: 3,
              boxShadow: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              {modalTitle}
            </Typography>
            {modalData.length > 0 ? (
              <List>
                {modalData.map((id, idx) => (
                  <ListItem key={idx}>
                    <ListItemText primary={id} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography>No data available</Typography>
            )}
          </Paper>
        </Modal>

        {/* Country Stats Modal */}
        <Modal open={countryModalOpen} onClose={handleCloseCountryModal}>
          <Paper
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 700,
              maxHeight: 500,
              overflowY: "auto",
              p: 3,
              boxShadow: 2,
              borderRadius: 2,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Country Statistics
            </Typography>
            {countryData ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <strong>Country</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Total Users</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Eligible Users</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Success Users</strong>
                      </TableCell>
                      <TableCell align="center">
                        <strong>Failed Users</strong>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.entries(countryData).map(
                      ([country, stats]: any) => (
                        <TableRow key={country}>
                          <TableCell>{country}</TableCell>
                          <TableCell align="center">
                            {stats.totalUsers.length}
                          </TableCell>
                          <TableCell align="center">
                            {stats.eligibleUsers.length}
                          </TableCell>
                          <TableCell align="center">
                            {stats.successUsers.length}
                          </TableCell>
                          <TableCell align="center">
                            {stats.failedUsers.length}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Typography>No data available</Typography>
            )}
          </Paper>
        </Modal>
      </Box>
    </>
  );
};

export default WeeklyReportStat;

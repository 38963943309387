/** @format */

import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import DatePicker from "react-datepicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TableContainer from "@mui/material/TableContainer";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import ActivitiesByDate from "../Components/ByDate/ActivitiesByDate";
import { CardContent, Dialog, DialogContent, TextField } from "@mui/material";

import {
  Container,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  Box,
  Card,
} from "@mui/material";
import MealTable from "../modules/MealTable";

import {
  calculateTotalCalorieAndProteinForWeekNew,
  filterLastSevenDaysWithDayNames,
  getWeekFromDate,
  extractNutritionDataForDate,
  filterLastSevenDaysSleepSessions,
} from "../services/userdetails.service";
import { accountService } from "../services";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import WorkoutByDate from "../Components/ByDate/WorkoutByDate";
import PopupComponent from "../modules/ImageModal";
import SleepByDate from "../Components/ByDate/SleepByDate";
import StepsByDate from "../Components/ByDate/StepsByDate";

import { AppState } from "../interface/User";
import ContentLoader from "react-content-loader";
interface UserActivityByDates {
  selectedUser: any;
  redirectingDate: any;
  appState: AppState;
  userDataLoading: boolean;
  currentTime: any;
  setCurrentTime: any;
}
const ShimmerLoader = () => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      margin: 0,
      padding: 0,
      overflow: "hidden", // Ensure no extra space from children
    }}
  >
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      style={{
        display: "block",
        margin: 0,
        padding: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden", // Prevent SVG overflow
      }}
    >
      {/* A single rectangle covering the entire card */}
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  </div>
);
const CustomInputStarDate = React.forwardRef(
  ({ value, onClick }: any, ref: any) => (
    <TextField
      value={value}
      onClick={onClick}
      ref={ref}
      variant="standard" // Removes the outline
      InputProps={{
        disableUnderline: true, // Removes the underline
        sx: {
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "1rem", // Adjust font size if needed
          cursor: "pointer", // Adds pointer cursor on hover
        },
      }}
      InputLabelProps={{
        shrink: true, // Keeps the label visible above the field
        sx: {
          textAlign: "center",
          width: "100%", // Centers the label
          fontWeight: "bold",
          cursor: "pointer",
        },
      }}
      sx={{
        width: "100%",
        "& .MuiInputBase-input": {
          textAlign: "center",
          pointerEvents: "none", // Prevents interference with cursor behavior
        },
        marginRight: 3,
        cursor: "pointer", // Adds cursor pointer to the entire field
      }}
    />
  )
);

function UserActivityByDates({
  selectedUser,
  redirectingDate,
  appState,
  userDataLoading,
  currentTime,
  setCurrentTime,
}: UserActivityByDates) {
  const [activity, setActivity] = React.useState("meal");
  const [mainActivity, setMainActivity] = React.useState("");

  const [imageModal, setImageModal] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState();
  const [videoAttachment, setVideoAttachment] = React.useState<boolean>();

  // Ref for managing the delay mechanism

  const [open, setOpen] = React.useState(false);

  const [nutritionData, setNutritionData] = React.useState({
    date: "",

    calorie: 0,
    protein: 0,
    carbohydrates: 0,
    fat: 0,
    monoUnsaturatedFat: 0,
    polyUnsaturatedFat: 0,
    saturatedFat: 0,
    transFat: 0,
    fiber: 0,
    sugar: 0,
    steps: 0,
    water: 0,
    sleep: 0,
    meal: [] as { mealName: string; ingredients: any[] }[],
  });

  /// Function for setting the daily nutrition -------------------------------------------------------/
  async function setNutrition(
    protienCalorieWeekData: any,
    date: any,
    stepsWeekData: any,
    waterWeekData: any,
    sleepWeekData: any
  ) {
    /// Function for getting the daily nutrition details----------------------/
    let response = await extractNutritionDataForDate(
      protienCalorieWeekData,
      date,
      stepsWeekData,
      waterWeekData,
      sleepWeekData
    );

    /// object destructuring all the values from ht eresponse
    const {
      matchedData,
      mealArray,
      dateString,
      stepsFinalData,
      waterFinalData,
      sleepFinalData,
    } = response;

    if (matchedData) {
      /// Storing The Values inside the nutriotion state-------------------------/
      setNutritionData((prevState) => ({
        ...prevState,
        date: dateString,
        calorie: matchedData.totalCalorie ? matchedData.totalCalorie : 0,
        protein: matchedData.totalProtein ? matchedData.totalProtein : 0,
        carbohydrates: matchedData.totalCarbohydrates
          ? matchedData.totalCarbohydrates
          : 0,
        fat: matchedData.totalFat ? matchedData.totalFat : 0,
        monoUnsaturatedFat: matchedData.totalMonoUnsaturatedFat
          ? matchedData.totalMonoUnsaturatedFat
          : 0,
        transFat: matchedData.totalTransFat ? matchedData.totalTransFat : 0,
        polyUnsaturatedFat: matchedData.totalPolyUnsaturatedFat
          ? matchedData.totalPolyUnsaturatedFat
          : 0,
        saturatedFat: matchedData.totalSaturatedFat
          ? matchedData.totalSaturatedFat
          : 0,

        fiber: matchedData.totalFiber ? matchedData.totalFiber : 0,
        sugar: matchedData.totalSugar ? matchedData.totalSugar : 0,
        steps: stepsFinalData ? stepsFinalData.steps : "- -",
        water: waterFinalData ? waterFinalData.water : "- -",
        sleep: sleepFinalData ? sleepFinalData.sleep_session : "- -",
        meal: mealArray || [], // Set mealArray or an empty array if no meal data is available
      }));
    } else {
    }
  }
  const handleDateChange = (newDate: any) => {
    // Check if the date is a dayjs object
    setCurrentTime(newDate);
    getTotalCaloriesAndPhyscialActivity(
      appState.meals,
      appState.physicalData,
      appState.workoutByDate,
      newDate
    );
    setOpen(false); // Close calendar on date selection
  };
  /// Function For getting total calorie,protien,steps and water -------------------------------------/
  async function getTotalCaloriesAndPhyscialActivity(
    data: any,
    physicalData: any,
    workoutByDate: any,
    date: any
  ) {
    const weekArray = await getWeekFromDate(date);

    /// Calculation of total Calorie and protien-------------------------------/
    const details = await calculateTotalCalorieAndProteinForWeekNew(
      data,
      weekArray
    );

    /// Calculation of total Steps----------------------------------------------/
    const stepsWeekArray = await filterLastSevenDaysWithDayNames(
      physicalData?.steps,
      "steps",
      weekArray
    );

    /// Function call for total water-------------------------------------------/
    const waterWeekArray = await filterLastSevenDaysWithDayNames(
      physicalData?.water,
      "water",
      weekArray
    );

    /// Function call for total water-------------------------------------------/
    const sleepWeekArray = await filterLastSevenDaysSleepSessions(
      physicalData?.sleep,

      weekArray
    );

    /// Calling The function which sets daily nutrition---------------------/
    setNutrition(details, date, stepsWeekArray, waterWeekArray, sleepWeekArray);
  }

  /// Function for protien,calorie steps and water getting-----------------/
  async function handelMealsAndActivity(
    redirectingDate: any,
    appState: AppState
  ) {
    try {
      let date;

      if (redirectingDate) {
        // If redirectingDate is defined and not null/undefined
        date = new Date(redirectingDate);
      } else {
        // If redirectingDate is undefined or null, use the current date
        date = new Date();
      }

      getTotalCaloriesAndPhyscialActivity(
        appState.meals,
        appState.physicalData,
        appState.workoutByDate,
        date
      );
      // Service function for getting the physical values like water and step
    } catch (e) {
      // setInitialDataLoading(false);
    }
  }

  // Adapted useEffect hook with delay mechanism
  React.useEffect(() => {
    setActivity(mainActivity !== "" ? mainActivity : "meal");

    let date;
    //setInitialDataLoading(true);

    // Your existing logic to be executed after the 1-second delay
    if (redirectingDate) {
      date = new Date(redirectingDate);
      setCurrentTime(date);
    } else {
      /// check it's the first user or not if first user then new date
      /// we will set else already the date is there will use that
      if (currentTime) {
        date = currentTime;
      } else {
        date = new Date();
        setCurrentTime(date);
      }
    }

    // Call your asynchronous operation here
    handelMealsAndActivity(date, appState).finally(() => {
      // Turn off the loading indicator once the operation is complete
      // setInitialDataLoading(false);
    });
  }, [selectedUser, appState, currentTime]); // Effect dependencies

  /// Function for increasing the date with arrow--------------------------/
  const increaseDate = async () => {
    const newDate = new Date(currentTime);
    newDate.setDate(newDate.getDate() + 1);
    setCurrentTime(newDate);
    getTotalCaloriesAndPhyscialActivity(
      appState.meals,
      appState.physicalData,
      appState.workoutByDate,
      newDate
    );
  };

  /// Function for decreasing the date with arrow----------------------------/
  const decreaseDate = async () => {
    const newDate = new Date(currentTime);
    newDate.setDate(newDate.getDate() - 1);
    setCurrentTime(newDate);
    getTotalCaloriesAndPhyscialActivity(
      appState.meals,
      appState.physicalData,
      appState.workoutByDate,
      newDate
    );
  };

  return (
    <>
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <ToggleButtonGroup
          value={activity}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              // to prevent unselecting the currently selected button
              setMainActivity(newValue);
              setActivity(newValue);
            }
          }}
          style={{ marginTop: 5 }}
          aria-label="activity"
        >
          <ToggleButton
            value="meal"
            aria-label="by date"
            sx={{
              "@media screen and (max-width: 1272px)": {
                padding: "1px", // Padding for screens smaller than 1202px
                fontSize: "15px",
              },
            }}
          >
            Meals
          </ToggleButton>

          <ToggleButton
            value="sets"
            aria-label="user details"
            sx={{
              "@media screen and (max-width: 1272px)": {
                padding: "1px", // Padding for screens smaller than 1202px
                fontSize: "15px",
              },
            }}
          >
            Workout
          </ToggleButton>
          <ToggleButton
            value="sleep"
            aria-label="user details"
            sx={{
              "@media screen and (max-width: 1272px)": {
                padding: "1px", // Padding for screens smaller than 1202px
                fontSize: "15px",
              },
            }}
          >
            Sleep
          </ToggleButton>
          <ToggleButton
            value="steps"
            aria-label="user details"
            sx={{
              "@media screen and (max-width: 1272px)": {
                padding: "1px", // Padding for screens smaller than 1202px
                fontSize: "15px",
              },
            }}
          >
            Steps
          </ToggleButton>
          <ToggleButton
            value="activities"
            aria-label="user details"
            sx={{
              "@media screen and (max-width: 1272px)": {
                padding: "1px", // Padding for screens smaller than 1202px
                fontSize: "15px",
              },
            }}
          >
            Activities
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>

      {activity === "sets" && (
        <>
          {userDataLoading ? (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <CircularProgress sx={{ height: 7, width: 7, marginTop: 10 }} />
            </Container>
          ) : (
            <Box sx={{ marginTop: 5 }}>
              <WorkoutByDate
                currentDate={currentTime}
                setCurrentDate={setCurrentTime}
                workout={appState.workoutByDate}
              />
            </Box>
          )}
        </>
      )}

      {activity === "activities" && (
        <>
          {userDataLoading ? (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <CircularProgress sx={{ height: 7, width: 7, marginTop: 10 }} />
            </Container>
          ) : (
            <Box sx={{ marginTop: 5 }}>
              <ActivitiesByDate
                selectedUser={selectedUser}
                activityData={appState.activityData}
                currentDate={currentTime}
                setCurrentDate={setCurrentTime}
              />
            </Box>
          )}
        </>
      )}
      {activity === "sleep" && (
        <>
          {userDataLoading ? (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <CircularProgress sx={{ height: 7, width: 7, marginTop: 10 }} />
            </Container>
          ) : (
            <Box sx={{ marginTop: 5 }}>
              <SleepByDate
                selectedUser={selectedUser}
                sleep={appState.physicalData.sleep}
                currentDate={currentTime}
                setCurrentDate={setCurrentTime}
              />
            </Box>
          )}
        </>
      )}
      {activity === "steps" && (
        <>
          {userDataLoading ? (
            <Container
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <CircularProgress sx={{ height: 7, width: 7, marginTop: 10 }} />
            </Container>
          ) : (
            <Box sx={{ marginTop: 5 }}>
              <StepsByDate
                selectedUser={selectedUser}
                steps={appState.physicalData.steps}
                currentDate={currentTime}
                setCurrentDate={setCurrentTime}
              />
            </Box>
          )}
        </>
      )}
      {activity === "meal" && (
        <>
          {userDataLoading ? (
            <ShimmerLoader />
          ) : (
            <>
              <Container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingTop: 2,
                }}
              >
                <ArrowBackIosIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    decreaseDate();
                  }}
                />

                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  {currentTime ? (
                    <DatePicker
                      selected={new Date(currentTime)}
                      onChange={(date: any) => handleDateChange(date)}
                      customInput={<CustomInputStarDate />}
                    />
                  ) : (
                    ""
                  )}
                </LocalizationProvider>
                <ArrowForwardIosIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    if (currentTime.getDate() === new Date().getDate()) {
                      return;
                    } else {
                      increaseDate();
                    }
                  }}
                />
              </Container>
              <Container
                style={{
                  display: "flex",

                  flexDirection: "column",
                }}
              >
                <Container
                  style={{
                    display: "flex",

                    flexDirection: "row", // Add this line
                    alignItems: "flex-start", // Add this line
                    justifyContent: "flex-start",

                    padding: "5px",
                    gap: "0px",
                  }}
                >
                  {Object.entries(nutritionData)
                    .filter(([key, _]) => {
                      // Filter out the unwanted keys
                      return ![
                        "date",
                        "meal",
                        "monoUnsaturatedFat",
                        "polyUnsaturatedFat",
                        "saturatedFat",
                        "transFat",
                        "steps",
                        "water",
                        "sleep",
                      ].includes(key);
                    })
                    .map(([key, value]) => (
                      <Card
                        key={key}
                        style={{
                          width: "calc(100% / 4 - 20px)", // Divide by 4 for 4 items, minus margin and gap
                          margin: "5px",
                          padding: "10px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",

                          boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                          borderRadius: "10px",
                          position: "relative",
                        }}
                      >
                        <CardContent style={{ padding: "5px" }}>
                          <div style={{ width: "100%", textAlign: "start" }}>
                            {" "}
                            {/* Add this div */}
                            <Typography
                              variant="body2"
                              component="h2"
                              style={{
                                marginBottom: "2px",
                                fontSize: "14px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis", // Use ellipsis for overflow
                              }}
                            >
                              {key.trim().toLowerCase() === "carbohydrates"
                                ? "Carbs"
                                : key
                                    .replace(/([A-Z])/g, " $1")
                                    .replace(/^./, (str) => str.toUpperCase())}
                            </Typography>
                          </div>
                          <Typography
                            variant="body2"
                            component="p"
                            align="center"
                            style={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis", // Use ellipsis for overflow
                            }}
                          >
                            {Number.isInteger(value)
                              ? value.toString()
                              : typeof value === "number"
                              ? value.toFixed(1)
                              : String(value)}
                          </Typography>
                          <Typography
                            color="textSecondary"
                            align="center"
                            style={{
                              fontSize: "10px",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis", // Use ellipsis for overflow
                            }}
                          >
                            {key.includes("calorie") ? "kcal" : "g"}
                          </Typography>
                        </CardContent>
                      </Card>
                    ))}
                </Container>
              </Container>
              <Container
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center", // Center items horizontally
                  marginBottom: 5,
                  zIndex: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between", // Spread items evenly
                    width: "100%", // Occupy full width
                    marginBottom: "10px", // Add some margin at the bottom
                  }}
                >
                  {/* Card for Steps */}
                  <Card
                    style={{
                      flex: 1, // Adjust width to occupy 1/3 of the space
                      margin: "0px 5px",
                      padding: "10px",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      position: "relative",
                      display: "flex",
                      alignItems: "center", // Align items vertically
                      justifyContent: "center",
                    }}
                  >
                    <span
                      role="img"
                      aria-label="steps"
                      style={{ fontSize: "24px", marginRight: "10px" }}
                    >
                      🏃‍♂️
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="h2"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        Steps
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        {nutritionData.steps == 0 ? "- -" : nutritionData.steps}
                      </Typography>
                    </div>
                  </Card>

                  {/* Card for Water */}
                  <Card
                    style={{
                      flex: 1, // Adjust width to occupy 1/3 of the space
                      margin: "0px 5px",
                      padding: "10px",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      position: "relative",
                      display: "flex",
                      alignItems: "center", // Align items vertically
                      justifyContent: "center",
                    }}
                  >
                    <span
                      role="img"
                      aria-label="water"
                      style={{ fontSize: "24px", marginRight: "10px" }}
                    >
                      💧
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="h2"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        Water
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        {nutritionData.water == 0 ? "- -" : nutritionData.water}
                      </Typography>
                      <Typography color="textSecondary" align="center">
                        glasses
                      </Typography>
                    </div>
                  </Card>

                  {/* Card for Sleep */}
                  <Card
                    style={{
                      flex: 1, // Adjust width to occupy 1/3 of the space
                      margin: "0px 5px",
                      padding: "10px",
                      boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                      borderRadius: "10px",
                      position: "relative",
                      display: "flex",
                      alignItems: "center", // Align items vertically
                      justifyContent: "center",
                    }}
                  >
                    <span
                      role="img"
                      aria-label="sleep"
                      style={{ fontSize: "24px", marginRight: "10px" }}
                    >
                      😴
                    </span>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        component="h2"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        Sleep
                      </Typography>
                      <Typography
                        variant="body2"
                        component="p"
                        align="center"
                        style={{ marginBottom: "5px" }}
                      >
                        {nutritionData.sleep == 0 ? "- -" : nutritionData.sleep}
                      </Typography>
                      <Typography color="textSecondary" align="center">
                        hours
                      </Typography>
                    </div>
                  </Card>
                </div>
              </Container>

              {nutritionData.meal && nutritionData.meal.length > 0 ? (
                <TableContainer
                  component={Paper}
                  sx={{ height: "55vh", overflowY: "scroll" }}
                >
                  <Table aria-label="collapsible table">
                    <TableBody>
                      {nutritionData.meal.map(
                        (row: any, index: number) =>
                          row[0] !== "Single Meal :" && (
                            <MealTable
                              key={index}
                              row={row}
                              setImageUrl={setImageUrl}
                              setImageModal={setImageModal}
                            />
                          )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography
                  style={{
                    textAlign: "center",
                    marginTop: "20px",
                    marginBottom: 20,
                  }}
                >
                  No meals available
                </Typography>
              )}
            </>
          )}
        </>
      )}
      <PopupComponent
        isPopupOpen={imageModal}
        imageUrl={imageUrl}
        setIsPopupOpen={setImageModal}
      />
    </>
  );
}

export default UserActivityByDates;

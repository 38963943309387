/** @format */

import { workoutUtilsFunctions } from "../utils/Workout";
import {
  ExerciseTableInterface,
  SetInterface,
} from "../interface/OwnCreatedWorkoutInterface";
import { Divider, Typography } from "@mui/material";
import DumbbellIcon from "@mui/icons-material/FitnessCenter";
import TimeIcon from "@mui/icons-material/AccessTime";
import WeightIcon from "@mui/icons-material/BarChart";
import SetIcon from "@mui/icons-material/List";

export default function ExerciseTable({ workoutDetails }: any) {
  return (
    <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
      <div style={{ width: "100%", maxWidth: "600px" }}>
        {workoutDetails ? (
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            {/* Exercise details */}
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: 14,
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  marginTop: 5,
                }}
              >
                <Typography style={{ fontWeight: "bold" }}>
                  Exercise:
                </Typography>
                <Typography style={{ marginLeft: 2, fontWeight: "bold" }}>
                  {workoutDetails.exercise}
                </Typography>
              </div>
            </div>
            <Divider style={{ width: "98%" }} />
            {/* Sets details */}
            {workoutDetails.sets && workoutDetails.sets.length > 0 ? (
              workoutDetails.sets.map((set: any, setIndex: number) => (
                <div
                  key={setIndex}
                  style={{
                    marginBottom: "10px",
                    borderBottom: "1px solid #ccc",
                    paddingBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <SetIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        Set:
                      </Typography>
                    </div>
                    <Typography>{setIndex + 1}</Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <DumbbellIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        {set.set_duration ? "Duration:" : "Reps:"}
                      </Typography>
                    </div>
                    <Typography>
                      {set.set_duration
                        ? set.set_duration < 60
                          ? `${set.set_duration} sec`
                          : `${Math.floor(set.set_duration / 60)} min`
                        : set.reps}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <WeightIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        Weight:
                      </Typography>
                    </div>
                    <Typography>
                      {set.isBodyWeight === 1 || set.weight === null
                        ? "Bodyweight"
                        : `${set.weight ?? ""} ${set.weightUnit}`}
                    </Typography>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <TimeIcon sx={{ fontSize: 18 }} />
                      <Typography sx={{ fontWeight: "bold", marginLeft: 1 }}>
                        Date:
                      </Typography>
                    </div>
                    <Typography>
                      {set.dateTime
                        ? new Date(set.dateTime).toLocaleTimeString("en-US", {
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        : ""}
                    </Typography>
                  </div>
                </div>
              ))
            ) : (
              <div style={{ marginBottom: "10px" }}>
                <strong>No sets available</strong>
              </div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Typography>No exercises available</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

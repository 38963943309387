import { fetchWrapper } from "../helpers";
import { config } from "../shared/constants/config";
const baseUrl = `${config.apiUrl}/api`;
export const chatService = {
  chatPost,
  chatGet,
  supportChatGet,
  supportChatPost,
  trainerChatGet,
  trainerChatPost,
  messageSeen,
  getAllConversation,
};
async function chatPost(trainerId: any, clientId: any, conversation: any) {
  return fetchWrapper
    .post(`${baseUrl}/trainer-chats`, { trainerId, clientId, conversation })
    .then((user) => {
      return user;
    });
}
async function chatGet(trainerId: any, clientId: any) {
  return fetchWrapper
    .get(`${baseUrl}/trainer-chats?clientId=${clientId}&trainerId=${trainerId}`)
    .then((user) => {
      return user;
    });
}

async function supportChatGet(userId: string) {
  return fetchWrapper.get(`${baseUrl}/user/support-chat-get/?userId=${userId}`);
}

async function supportChatPost(
  userId: string,
  conversation: any,
  loggedUser: string
) {
  return fetchWrapper.post(
    `${baseUrl}/user/support-chat/?userId=${userId}&adminId=${loggedUser}`,
    {
      conversation,
    }
  );
}

/// Function for getting all the chats ---------------------------------------------/
async function getAllConversation() {
  return fetchWrapper.get(`${baseUrl}/get-all-chatconversation`);
}

async function trainerChatGet(userId: string) {
  return fetchWrapper.get(`${baseUrl}/user/trainer-chat-get/?userId=${userId}`);
}

async function trainerChatPost(
  userId: string,
  conversation: any,
  loggedUser: string
) {
  return fetchWrapper.post(
    `${baseUrl}/user/trainer-chat/?userId=${userId}&trainerId=${loggedUser}`,
    {
      conversation,
    }
  );
}

/// Function For making the newmessage seen as false so ------------------------------------/
async function messageSeen(userId: string, mainUserRole: string) {
  return fetchWrapper.put(`${baseUrl}/user/support-chat-seen`, {
    userId,
    mainUserRole,
  });
}

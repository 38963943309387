/** @format */

import { config } from "../shared/constants/config";
import { NutritionLogResponse } from "../interface/NutritionLogInterface";
import { fetchWrapper } from "../helpers";
const baseUrl = `${config.apiUrl}/api`;
export const nutritionlogsService = {
  getNutritionLogs,
};

//// Function For handeling the api for getting the nutrition logs-----------------/
async function getNutritionLogs(range: {
  start: number;
  end: number;
}): Promise<NutritionLogResponse> {
  return fetchWrapper.get(
    `${baseUrl}/get-nutritionlogs?start=${range.start}&end=${range.end}`
  );
}

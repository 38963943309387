/** @format */

import { Dialog, DialogActions, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Container from "@mui/material/Container";

// ...
interface PopupComponent {
  isPopupOpen: any;
  setIsPopupOpen: any;
  imageUrl: string | any;
}

export default function PopupComponent({
  isPopupOpen,
  setIsPopupOpen,
  imageUrl,
}: PopupComponent) {
  return (
    <Dialog
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      PaperProps={{
        style: {
          background: "transparent",
          boxShadow: "none",
        },
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "10px",
          right: "20px",
          backgroundColor: "white",
          borderRadius: "50%",
          width: "40px",
          height: "40px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setIsPopupOpen(false)}
      >
        <CloseIcon style={{ color: "black" }} />
      </div>
      <Container
        style={{
          width: "60%",
          height: "70%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
            width: "100%",
          }}
        >
          <img
            src={imageUrl}
            style={{
              width: "100%", // Adjusted width to take up more space
              margin: "auto",
            }}
            alt="content"
          />
        </div>
      </Container>
    </Dialog>
  );
}

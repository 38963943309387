import { accountService } from "./account.service";

//// Funciton for exporting all the route services ------------------------------------/
export const routeService = {
  getDashboardPermission,
};

/// Funciton for fetching the dasboard- permissions json file ------------------------/

async function getDashboardPermission(role: string): Promise<Array<string>> {
  const url =
    "https://nomatictechstorage.blob.core.windows.net/reshape-public/dashboard-permissions.json";

  try {
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Check the logged-in user's role permissions
    let matched = false;

    for (let i = 0; i < data.length; i++) {
      if (role === data[i].role) {
        matched = true;
        return data[i].permission; // Return permissions for matched role
      }
    }

    if (!matched) {
      return []; // Return empty array if user role doesn't match any in data
    }
  } catch (error) {
    console.error("Failed to fetch dashboard permissions:", error);
    return []; // Return empty array in case of fetch or processing error
  }

  return []; // Fallback return in case of unexpected control flow (though not expected to reach)
}

import { CardContent, CircularProgress, Typography } from "@mui/material";
import { memo } from "react";
import { Interface } from "readline";
import { boolean } from "yup";
import { AppState } from "../../interface/User";

interface UserAppActivity {
  appState: AppState;
}

/// Function For Showing The Last Activity -------------------------------/
function showActivity(key: any, value: any) {
  if (key == "id" || key == "apiId") {
    return null;
  } else if (key === "dateTime") {
    return (
      <Typography sx={{ mb: 1.5 }} color="text.secondary" key={key}>
        {key}: {value}
      </Typography>
    );
  } else {
    return (
      <Typography sx={{ mb: 1.5 }} color="text.secondary" key={key}>
        {key}: {value}
      </Typography>
    );
  }
}

//// Main funciton for the User App Activity --------------------------------------------/
function UserAppActivity({ appState }: UserAppActivity) {
  return (
    <CardContent>
      <Typography sx={{ fontSize: 20 }} color="#000" gutterBottom>
        App Activity
      </Typography>

      <>
        {appState.meals.length === 0 ? null : (
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",

              marginBottom: 5,
            }}
          >
            <Typography
              sx={{ mb: 1.5 }}
              color="text.secondary"
              style={{
                fontWeight: "700",
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              {appState.lastAppActivityData.tableName}
            </Typography>
            {appState.lastAppActivityData ? (
              Object.entries(appState.lastAppActivityData.activityData).map(
                ([key, value]) => showActivity(key, value)
              )
            ) : (
              <p>Loading...</p>
            )}
          </CardContent>
        )}
      </>
    </CardContent>
  );
}

export default memo(UserAppActivity);

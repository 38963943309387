import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";

interface InputField {
  title: string;
  options: { id: number; text: string }[];
  answers: number[];
  correctAnswer: string;
  wrongAnswer: string;
  paragraph: string;
}

interface PageContent {
  input_fields: string; // JSON string of input fields
  sequence: number;
  type: string; // Type of content (e.g., quiz, paragraph, etc.)
}

interface LessonPages {
  uuid: string;
  lesson_id: string;
  pageName: string;
  pageBannerImage?: string;
  pageButtonText: string;
  pageNumber: number;
  pageContent: PageContent[];
}

interface Props {
  pages: any;
}

const PagePreview: React.FC<Props> = ({ pages }) => {
  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  if (!pages || pages.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: 2,
        }}
      >
        <Typography variant="h6" color="textSecondary" gutterBottom>
          No pages available to display.
        </Typography>
      </Box>
    );
  }

  const handleNextPage = () => {
    if (currentPageIndex < pages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  };
  const renderPageContent = (pageContent: PageContent[]) => {
    if (pageContent && pageContent.length > 0) {
      // Sort the content only if pageContent has more than 0 items
      const sortedContent = pageContent.sort((a, b) => a.sequence - b.sequence);

      return sortedContent.map((content, index) => {
        let inputFields: InputField;

        try {
          // Check if input_fields is a string before parsing
          if (typeof content.input_fields === "string") {
            inputFields = JSON.parse(content.input_fields);
          } else {
            inputFields = content.input_fields;
          }

          switch (content.type) {
            case "quizSingle":
            case "quizMultiple":
              return (
                <Box key={index} mb={3} p={3} borderRadius={2} boxShadow={2}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color="primary"
                  >
                    {inputFields.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    color="textSecondary"
                  >
                    Choose your option:
                  </Typography>
                  <Box ml={2}>
                    {inputFields.options.map((option) => (
                      <Typography
                        key={option.id}
                        variant="body1"
                        style={{
                          marginBottom: "8px",
                          padding: "8px",
                          backgroundColor: "#f5f5f5",
                          borderRadius: "4px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        - {option.text}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              );
            case "paragraph":
              return (
                <Box key={index} mb={3} p={3} borderRadius={2} boxShadow={2}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color="primary"
                  >
                    {inputFields.title}
                  </Typography>
                </Box>
              );
            case "quizFreeText":
              return (
                <Box key={index} mb={3} p={3} borderRadius={2} boxShadow={2}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color="primary"
                  >
                    {inputFields.title}
                  </Typography>
                  <Typography variant="body1" color="textSecondary" paragraph>
                    {inputFields.paragraph}
                  </Typography>
                </Box>
              );
            case "paragraphBanner":
              return (
                <Box key={index} mb={3} p={3} borderRadius={2} boxShadow={2}>
                  <Typography
                    variant="h6"
                    fontWeight="bold"
                    gutterBottom
                    color="primary"
                  >
                    {inputFields.title}
                  </Typography>
                </Box>
              );
            // Add more cases for other content types
            default:
              return (
                <Box key={index} mb={3}>
                  <Typography color="error">
                    {`Unknown content type: ${content.type}`}
                  </Typography>
                </Box>
              );
          }
        } catch (error) {
          console.error("Error parsing input_fields:", error);
          return (
            <Box key={index} mb={3}>
              <Typography color="error">{`Error loading content: ${content.type}`}</Typography>
            </Box>
          );
        }
      });
    } else {
      return (
        <Box mb={3}>
          <Typography>No content available</Typography>
        </Box>
      );
    }
  };

  const currentPage = pages[currentPageIndex];

  return (
    <Box
      sx={{
        border: "1px solid #ccc",
        borderRadius: 2,
        height: "600px",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      {/* Scrollable Content */}
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          padding: 2,
        }}
      >
        {/* Page Details */}
        <Typography variant="h4" gutterBottom>
          {currentPage.pageName}
        </Typography>
        {currentPage.pageBannerImage && (
          <Box
            component="img"
            src={currentPage.pageBannerImage}
            alt="Page Banner"
            sx={{
              width: "100%",
              height: 150, // Adjusted image height
              objectFit: "contain",
              borderRadius: 2,
              mb: 2,
            }}
          />
        )}

        {/* Page Content */}
        {renderPageContent(currentPage.pageContent)}

        {/* Page Button */}
        <Button
          variant="contained"
          fullWidth
          sx={{
            textTransform: "none",
            fontSize: "16px",
            backgroundColor: "#1976d2",
            "&:hover": { backgroundColor: "#1565c0" },
          }}
        >
          {currentPage.pageButtonText}
        </Button>
      </Box>

      {/* Navigation Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
          backgroundColor: "#f9f9f9",
          position: "sticky",
          bottom: 0,
          borderTop: "1px solid #ddd",
        }}
      >
        <Button
          variant="contained"
          disabled={currentPageIndex === 0}
          onClick={handlePreviousPage}
        >
          Previous
        </Button>
        <Button
          variant="contained"
          disabled={currentPageIndex === pages.length - 1}
          onClick={handleNextPage}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default PagePreview;
